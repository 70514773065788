import { ResponsiveBar } from '@nivo/bar'

import { cn } from 'src/utils'

const DiscussionTopicsBarChart = ({ data, isEmpty }) => {
  return (
    <div className={cn('h-[225px]')}>
      {isEmpty ? (
        <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
          No data available
        </div>
      ) : (
        <ResponsiveBar
          data={data}
          keys={['frequency']}
          indexBy="topic"
          margin={{ top: 20, right: 50, bottom: 25, left: 100 }}
          padding={0.3}
          enableGridY={false}
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={'#77BEE7'}
          enableTotals={true}
          enableLabel={false}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisLeft={{
            format: (label) => {
              const formattedLabel =
                label === 'payment' ? 'compensation' : label
              const capitalizedLabel =
                formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1)
              return capitalizedLabel.length > 13
                ? `${capitalizedLabel.slice(0, 13)}...`
                : capitalizedLabel
            },
          }}
          tooltip={(e) => (
            <div className="rounded-lg bg-white p-2 shadow-md">
              <div className="flex flex-row items-center gap-10">
                <div className="text-sm font-semibold capitalize text-doubleNickel-gray-900">
                  {e.indexValue}:
                </div>
                <div className="text-sm text-doubleNickel-gray-500">
                  {e.value}
                </div>
              </div>
            </div>
          )}
          axisRight={null}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 12, // Font size of axis ticks
                },
              },
            },
          }}
          axisBottom={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
        />
      )}
    </div>
  )
}

export default DiscussionTopicsBarChart
