import { cn } from 'src/utils'

const TotalCallStats = ({ dailyCallActivityMetrics }) => {
  const total = dailyCallActivityMetrics.reduce(
    (acc, curr) => acc + curr.count,
    0
  )

  const inbound = dailyCallActivityMetrics
    .filter((item) => item.callDirection === 'INBOUND')
    .reduce((acc, curr) => acc + curr.count, 0)

  const outbound = dailyCallActivityMetrics
    .filter((item) => item.callDirection === 'OUTBOUND')
    .reduce((acc, curr) => acc + curr.count, 0)

  return (
    <div className="flex flex-row items-center justify-around">
      <div>
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {total}
        </div>
        <div className="text-sm font-medium text-doubleNickel-gray-600">
          Total Calls
        </div>
      </div>
      <div>
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {inbound}
          <div
            className={cn(
              'text-sm ',
              outbound > inbound
                ? 'text-doubleNickel-warning-500'
                : 'text-doubleNickel-success-500'
            )}
          >
            {total ? Math.round((inbound / total) * 100) : '0'}%
          </div>
        </div>
        <div className="text-sm font-medium text-doubleNickel-gray-600">
          Inbound Calls
        </div>
      </div>
      <div>
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {outbound}
          <div
            className={cn(
              'text-sm ',
              outbound < inbound
                ? 'text-doubleNickel-warning-500'
                : 'text-doubleNickel-success-500'
            )}
          >
            {total ? Math.round((outbound / total) * 100) : '0'}%
          </div>
        </div>
        <div className="text-sm font-medium text-doubleNickel-gray-600">
          Outbound Calls
        </div>
      </div>
    </div>
  )
}

export default TotalCallStats
