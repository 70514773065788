import { Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_CALL_DURATION_AVERAGE_METRIC,
  GET_TOTAL_CALL_DURATION_METRIC,
} from 'src/graphql/callAnaltyics.graphql'
import IconLeftArrow from 'src/icons/IconLeftArrow'
import IconRightArrow from 'src/icons/IconRightArrow'
import { cn } from 'src/utils'

const CallDurationStats = ({ filters }) => {
  const { data, loading } = useQuery(GET_TOTAL_CALL_DURATION_METRIC, {
    onError: () => {
      toast('Unable to get call duration metrics', 'error')
    },
    variables: {
      filters: filters,
    },
  })

  const { data: callDurationAverageData } = useQuery(
    GET_CALL_DURATION_AVERAGE_METRIC,
    {
      onError: () => {
        toast('Unable to get call duration metrics', 'error')
      },
      variables: {
        filters: filters,
      },
    }
  )

  const inbound =
    data?.getTotalCallDurationMetric.find(
      (item) => item.callDirection === 'INBOUND'
    )?.duration || 0
  const outbound =
    data?.getTotalCallDurationMetric.find(
      (item) => item.callDirection === 'OUTBOUND'
    )?.duration || 0

  const inboundInMinutes = inbound / 60
  const outboundInMinutes = outbound / 60
  const totalInMinutes = inboundInMinutes + outboundInMinutes

  const showInboundInHours = inboundInMinutes >= 60
  const showOutboundInHours = outboundInMinutes >= 60
  const showTotalInHours = totalInMinutes >= 60

  const averageDuration =
    callDurationAverageData?.getCallDurationAverageMetric?.averageDuration || 0
  const averageDurationInMinutes = averageDuration / 60
  const showAverageDurationInHours = averageDurationInMinutes >= 60

  return (
    <div className="grid h-[225px] grid-cols-2 overflow-hidden">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {loading ? (
            <Loader size={26} color="gray" />
          ) : showTotalInHours ? (
            (totalInMinutes / 60).toFixed(1)
          ) : (
            totalInMinutes.toFixed(1)
          )}
          <div className={cn('text-sm ')}>
            {showTotalInHours ? 'hours' : 'minutes'}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-600">
          Total Calls
          <IconRightArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-success-500" />
          <IconLeftArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-warning-500" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {loading ? (
            <Loader size={26} color="gray" />
          ) : showAverageDurationInHours ? (
            (averageDurationInMinutes / 60).toFixed(1)
          ) : (
            averageDurationInMinutes.toFixed(1)
          )}
          <div className={cn('text-sm ')}>
            {showAverageDurationInHours ? 'hours' : 'minutes'}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-600">
          Avg Duration
          <IconRightArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-success-500" />
          <IconLeftArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-warning-500" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {loading ? (
            <Loader size={26} color="gray" />
          ) : showInboundInHours ? (
            (inboundInMinutes / 60).toFixed(1)
          ) : (
            inboundInMinutes.toFixed(1)
          )}
          <div className={cn('text-sm ')}>
            {showInboundInHours ? 'hours' : 'minutes'}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-600">
          Inbound Calls
          <IconLeftArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-warning-500" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
          {loading ? (
            <Loader size={26} color="gray" />
          ) : showOutboundInHours ? (
            (outboundInMinutes / 60).toFixed(1)
          ) : (
            outboundInMinutes.toFixed(1)
          )}
          <div className={cn('text-sm ')}>
            {showOutboundInHours ? 'hours' : 'minutes'}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-600">
          Outbound Calls
          <IconRightArrow className="h-4 w-4 -rotate-45 fill-none stroke-doubleNickel-success-500" />
        </div>
      </div>
    </div>
  )
}

export default CallDurationStats
