import { useState } from 'react'

import { Box, Divider } from '@mantine/core'

import { Metadata } from '@redwoodjs/web'

import { useCurrentCompanyContext } from 'src/context/CurrentCompanyContext'
import { cn } from 'src/utils'

import CallAnalyticsTab from './tabs/CallAnalyticsTab'
import VoiceAgentAnalyticsTab from './tabs/VoiceAgentAnalyticsTab'

const CallAnalyticsPage = () => {
  const company = useCurrentCompanyContext()
  const isVoiceAgentEnabled = company?.voiceAgentEnabled
  const tabFromUrl = new URLSearchParams(window.location.search).get('tab')
  const [selectedTab, setSelectedTab] = useState(tabFromUrl || 'general')

  const updateUrl = (tab) => {
    const url = new URL(window.location.href)
    // Clear all existing params
    url.search = ''
    if (tab) {
      url.searchParams.set('tab', tab)
    }
    window.history.replaceState(null, null, url)
  }

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
    updateUrl(tab)
  }

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden px-5 pb-5">
      <Metadata title="Call Analytics" description="Call Analytics page" />
      <div className="flex flex-row items-baseline gap-4 text-lg font-normal text-doubleNickel-gray-500 ">
        <Box
          className={cn(
            'cursor-pointer',
            selectedTab === 'general' && [
              'font-semibold text-doubleNickel-gray-800',
            ]
          )}
          onClick={() => handleTabChange('general')}
        >
          General
        </Box>

        {isVoiceAgentEnabled && (
          <>
            <Divider orientation="vertical" />
            <Box
              className={cn(
                'cursor-pointer',
                selectedTab === 'voice-agent' && [
                  'font-semibold text-doubleNickel-gray-800',
                ]
              )}
              onClick={() => handleTabChange('voice-agent')}
            >
              Voice Agent
            </Box>
          </>
        )}
      </div>
      {selectedTab === 'general' && <CallAnalyticsTab />}
      {selectedTab === 'voice-agent' && <VoiceAgentAnalyticsTab />}
    </div>
  )
}

export default CallAnalyticsPage
