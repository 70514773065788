import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Divider, Grid, Switch } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import Tag from 'src/components/DataDisplay/Tag/Tag'
import DateRangeMenu from 'src/components/Overlays/DateRangeMenu'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_APPLICANT_CALL_CONVERSION_BY_STATUS,
  GET_APPLICANT_CALL_CONVERSION_LIST,
  GET_COMMON_DISCUSSION_TOPICS,
} from 'src/graphql/callAnaltyics.graphql'
import { GET_JOB_LISTINGS_ONLY } from 'src/graphql/joblistings.graphql'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import { usePage } from 'src/hooks/usePage/usePage'
import { formatSnakeValue } from 'src/lib/formatters'

import FilterMenu from '../../../components/Overlays/FiltersMenu/FilterMenu'
import AnalyticsCard from '../../AnalyticsPage/components/AnalyticsCard'
import AverageCallCountStat from '../components/AverageCallCountStat'
import ConversionFunnel from '../components/ConversionFunnel'
import ConversionPieChart from '../components/ConversionPieChart'
import ConversionTable from '../components/ConversionTable'
import DiscussionTopicsBarChart from '../components/DiscussionTopicsBarChart'
import { connectedStatusOptions, leadSourceOptions, updateUrl } from '../utils'
import { getUrlParams } from '../utils'

const VoiceAgentAnalyticsTab = () => {
  const urlParams = getUrlParams()
  const [selectedJobListings, setSelectedJobListings] = useState(
    urlParams.defaultJobListings
  )
  const [selectedLeadSources, setSelectedLeadSources] = useState(
    urlParams.defaultLeadSources
  )
  const [selectedConnectedStatus, setSelectedConnectedStatus] = useState(
    urlParams.defaultConnectedStatus
  )
  const [startDate, setStartDate] = useState(urlParams.defaultStartDate)
  const [endDate, setEndDate] = useState(urlParams.defaultEndDate)
  const [showTransferred, setShowTransferred] = useState(false)
  const [page, setPage] = usePage()
  const limit = 24

  const { data: jobListingsResponse } = useQuery(GET_JOB_LISTINGS_ONLY, {
    variables: {
      pageInfo: { offset: 0, limit: 100 },
    },
  })

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  const { data: callConversionData, loading } = useQuery(
    GET_APPLICANT_CALL_CONVERSION_BY_STATUS,
    {
      variables: {
        filters: {
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
          leadSources: selectedLeadSources,
          connectedStatuses: selectedConnectedStatus,
          jobListings: selectedJobListings,
        },
      },
    }
  )

  const { data: discussionTopicsData, loading: discussionTopicsLoading } =
    useQuery(GET_COMMON_DISCUSSION_TOPICS, {
      variables: {
        filters: {
          jobListings: selectedJobListings,
          connectedStatuses: selectedConnectedStatus,
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
        },
      },
    })

  let discussionData = discussionTopicsData?.getCommonDiscussionTopics || []
  const temp = [...discussionData]
  discussionData = temp.reverse()
  const isEmpty = discussionData.length === 0

  const [
    loadApplicantCallConversion,
    {
      data: applicantCallConversionPage,
      loading: applicantCallConversionPageLoading,
    },
  ] = useLazyQuery(GET_APPLICANT_CALL_CONVERSION_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      pageInfo: {
        offset: (page - 1) * limit,
        limit,
      },
      filters: {
        dateRange: {
          gte: startDate,
          lte: endDate,
        },
        leadSources: selectedLeadSources,
        connectedStatuses: selectedConnectedStatus,
        jobListings: selectedJobListings,
      },
    },
    onError: (error) => {
      console.log('error', error)
      toast('Something went wrong, please try again.', 'error')
    },
  })

  useEffect(() => {
    loadApplicantCallConversion()
  }, [loadApplicantCallConversion])

  return (
    <>
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-1 flex-row gap-2 overflow-auto">
          <Tag showCloseButton={false}>Voice Agent</Tag>
          <Tag showCloseButton={false}>Outbound</Tag>
          {selectedLeadSources.map((source) => (
            <Tag
              key={source}
              onClose={() => {
                setSelectedLeadSources(
                  selectedLeadSources.filter((s) => s !== source)
                )
                updateUrl(
                  'leadSources',
                  selectedLeadSources.filter((s) => s !== source).join(',')
                )
              }}
            >
              {LinkTypeDisplay[source]}
            </Tag>
          ))}
          {selectedConnectedStatus.map((status) => (
            <Tag
              key={status}
              onClose={() => {
                setSelectedConnectedStatus(
                  selectedConnectedStatus.filter((s) => s !== status)
                )
                updateUrl(
                  'connectedStatus',
                  selectedConnectedStatus.filter((s) => s !== status).join(',')
                )
              }}
            >
              {formatSnakeValue(status)}
            </Tag>
          ))}
          {selectedJobListings.map((jobListing) => {
            const matchingOption = jobListingOptions.find(
              (j) => j.value === jobListing
            )
            if (!matchingOption) return null

            return (
              <Tag
                key={jobListing}
                onClose={() => {
                  const newJobListings = selectedJobListings.filter(
                    (j) => j !== jobListing
                  )
                  setSelectedJobListings(newJobListings)
                  updateUrl('jobListings', newJobListings.join(','))
                }}
              >
                {matchingOption.label}
              </Tag>
            )
          })}
        </div>
        <Divider orientation="vertical" className="ml-auto" />

        <FilterMenu
          filters={[
            {
              title: 'Lead Source',
              selected: selectedLeadSources,
              onChange: (value) => {
                setSelectedLeadSources(value)
                updateUrl('leadSources', value.join(','))
              },
              options: [...leadSourceOptions],
            },
            {
              title: 'Connected Status',
              selected: selectedConnectedStatus,
              onChange: (value) => {
                setSelectedConnectedStatus(value)
                updateUrl('connectedStatus', value.join(','))
              },
              options: [...connectedStatusOptions],
            },
            {
              title: 'Job Listings',
              selected: selectedJobListings,
              onChange: (value) => {
                setSelectedJobListings(value)
                updateUrl('jobListings', value.join(','))
              },
              options: jobListingOptions ? [...jobListingOptions] : [],
            },
          ]}
        />
        <DateRangeMenu
          startDate={startDate}
          endDate={endDate}
          handleChange={(value) => {
            setStartDate(value[0])
            setEndDate(value[1])
            updateUrl('dateRange', value.join(','))
          }}
          clearable={false}
        />
      </div>
      <Grid gutter="xs" className="h-full overflow-y-auto overflow-x-hidden">
        <Grid.Col span={5} className="flex flex-col gap-4">
          <AnalyticsCard
            title="Voice Agent Conversion Funnel"
            loading={loading}
            description={'Total calls to booked calls'}
            headerChildren={
              <Switch
                size="xs"
                className="mt-2"
                label="Show Transferred"
                labelPosition="left"
                checked={showTransferred}
                onChange={() => setShowTransferred((prev) => !prev)}
              />
            }
          >
            <ConversionFunnel
              showTransferred={showTransferred}
              data={
                callConversionData?.getApplicantCallConversionByStatus || {
                  total: 0,
                  statuses: [],
                }
              }
            />
          </AnalyticsCard>
          <AnalyticsCard
            title="Voice Agent Call Statuses"
            loading={loading}
            description={'Statuses of calls across all contact attempts'}
          >
            <ConversionPieChart
              data={
                callConversionData?.getApplicantCallConversionByStatus || {
                  total: 0,
                  statuses: [],
                }
              }
            />
          </AnalyticsCard>
          <AnalyticsCard
            title="Voice Agent Discussion Topics"
            showBadge={false}
            loading={discussionTopicsLoading}
            description={'Most common discussion topics across all calls'}
            headerChildren={<Tag showCloseButton={false}>All Lead Sources</Tag>}
          >
            <DiscussionTopicsBarChart data={discussionData} isEmpty={isEmpty} />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={7}>
          <AnalyticsCard
            title="Voice Agent Conversion Outcomes"
            loading={applicantCallConversionPageLoading}
            showBadge={false}
            description={
              'Tracking voice agent call conversion attempts for applicants'
            }
            headerChildren={<AverageCallCountStat />}
          >
            <ConversionTable
              data={
                applicantCallConversionPage?.getApplicantCallConversionList
                  ?.items || []
              }
              page={page}
              loading={applicantCallConversionPageLoading}
              totalPages={Math.ceil(
                applicantCallConversionPage?.getApplicantCallConversionList
                  ?.totalCount / limit
              )}
              onPageChange={setPage}
            />
          </AnalyticsCard>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default VoiceAgentAnalyticsTab
