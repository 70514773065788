import StyledAreaChart from 'src/pages/AnalyticsPage/components/StyledAreaChart/StyledAreaChart'

export const series = [
  { name: 'Inbound', color: '#FEC84B' },
  { name: 'Outbound', color: '#75E0A7' },
]

const CallAreaChart = ({ ...props }) => {
  return <StyledAreaChart useSampleData={false} series={series} {...props} />
}

export default CallAreaChart
