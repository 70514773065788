import { Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_AVERAGE_CALL_COUNT_METRIC } from 'src/graphql/callAnaltyics.graphql'
import { cn } from 'src/utils'

const AverageCallCountStat = () => {
  const { data, loading } = useQuery(GET_AVERAGE_CALL_COUNT_METRIC, {
    onError: () => {
      toast('Unable to get call count metrics', 'error')
    },
  })

  const averageCallCount =
    data?.getAverageCallCountMetric?.averageCallCount || 0

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-row items-baseline gap-2 text-xl text-doubleNickel-gray-800">
        {loading ? (
          <Loader size={20} color="gray" />
        ) : (
          averageCallCount.toFixed(1)
        )}
        <div className={cn('text-xs ')}>calls</div>
      </div>
      <div className="flex flex-row items-center gap-2 text-xs font-medium text-doubleNickel-gray-600">
        Avg Call Count
      </div>
    </div>
  )
}

export default AverageCallCountStat
