import { useContext } from 'react'

import FilterMenu from 'src/components/Overlays/FiltersMenu/FilterMenu'
import { useCurrentCompanyContext } from 'src/context/CurrentCompanyContext'
import { RecruitersContext } from 'src/context/RecruitersContext'

import {
  callDirectionOptions,
  callStatusOptions,
  callTypeOptions,
  connectedStatusOptions,
  updateUrl,
} from '../utils'

const Filters = ({
  callDirections,
  setCallDirections,
  selectedRecruiters,
  setSelectedRecruiters,
  callTypes,
  setCallTypes,
  callStatuses,
  setCallStatuses,
  selectedConnectedStatus,
  setSelectedConnectedStatus,
}) => {
  const recruiters = useContext(RecruitersContext)
  const company = useCurrentCompanyContext()
  const isVoiceAgentEnabled = company?.voiceAgentEnabled
  return (
    <FilterMenu
      filters={[
        {
          title: 'Recruiters',
          selected: selectedRecruiters,
          onChange: (value) => {
            setSelectedRecruiters(value)
            updateUrl('recruiters', value.join(','))
          },
          options: recruiters ? [...recruiters] : [],
        },
        {
          title: 'Call Direction',
          selected: callDirections,
          onChange: (value) => {
            setCallDirections(value)
            updateUrl('callDirections', value.join(','))
          },
          options: callDirectionOptions,
        },
        {
          title: 'Call Type',
          selected: callTypes,
          onChange: (value) => {
            setCallTypes(value)
            updateUrl('callTypes', value.join(','))
          },
          options: callTypeOptions,
        },
        {
          title: 'Call Status',
          selected: callStatuses,
          onChange: (value) => {
            setCallStatuses(value)
            updateUrl('callStatuses', value.join(','))
          },
          options: callStatusOptions,
        },
        ...(isVoiceAgentEnabled
          ? [
              {
                title: `Voice Agent Status`,
                selected: selectedConnectedStatus,
                onChange: (value) => {
                  setSelectedConnectedStatus(value)
                  updateUrl('connectedStatus', value.join(','))
                },
                options: connectedStatusOptions,
              },
            ]
          : []),
      ]}
    />
  )
}

export default Filters
