import SparklineStatistic from 'src/pages/AnalyticsPage/components/SparklineStatistic'

const series = [
  { name: 'No Answer', color: '#77BEE7' },
  { name: 'Busy', color: '#D0D5DD' },
  { name: 'Left Voicemail', color: '#FEC84B' },
  { name: 'Completed', color: '#75E0A7' },
  { name: 'Failed', color: '#FDA29B' },
]

const CallSparklineCard = ({ pipelineMetrics = [] }) => {
  return (
    <div className="flex h-[370px] flex-col gap-10 px-4">
      {series.map((item) => {
        return (
          <SparklineStatistic
            key={item.name}
            title={item.name}
            color={item.color}
            data={pipelineMetrics.map((metric) => {
              return metric[item.name]
            })}
            value={pipelineMetrics.reduce((acc, metric) => {
              return acc + metric[item.name]
            }, 0)}
          />
        )
      })}
    </div>
  )
}

export default CallSparklineCard
