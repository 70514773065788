export const GET_CALL_STATUSES_METRICS = gql`
  query GetCallStatusesMetrics($filters: CallLogFiltersInput) {
    callStatusesMetrics(filters: $filters) {
      callStatus
      date
      count
    }
  }
`

export const GET_DAILY_CALL_ACTIVITY_METRICS = gql`
  query GetDailyCallActivityMetrics($filters: CallLogFiltersInput) {
    dailyCallActivityMetrics(filters: $filters) {
      callDirection
      date
      count
    }
  }
`

export const GET_TOTAL_CALL_DURATION_METRIC = gql`
  query GetTotalCallDurationMetric($filters: CallLogFiltersInput) {
    getTotalCallDurationMetric(filters: $filters) {
      callDirection
      duration
    }
  }
`

export const GET_CALL_DURATION_AVERAGE_METRIC = gql`
  query GetCallDurationAverageMetric($filters: CallLogFiltersInput) {
    getCallDurationAverageMetric(filters: $filters) {
      averageDuration
    }
  }
`

export const GET_APPLICANT_CALL_CONVERSION_BY_STATUS = gql`
  query GetApplicantCallConversionByStatus($filters: CallLogFiltersInput) {
    getApplicantCallConversionByStatus(filters: $filters) {
      total
      statuses {
        status
        connectedStatus
        count
        percentage
      }
    }
  }
`

export const GET_CALL_CONVERSION_BY_RECRUITER = gql`
  query GetCallsByRecruiterMetric($filters: CallLogFiltersInput) {
    getCallsByRecruiterMetric(filters: $filters) {
      total
      items {
        recruiterId
        recruiterName
        count
        percentage
      }
    }
  }
`

export const GET_AVERAGE_CALL_COUNT_METRIC = gql`
  query GetAverageCallCountMetric($filters: CallLogFiltersInput) {
    getAverageCallCountMetric(filters: $filters) {
      averageCallCount
    }
  }
`

export const GET_APPLICANT_CALL_CONVERSION_LIST = gql`
  query GetApplicantCallConversionList(
    $filters: CallLogFiltersInput
    $pageInfo: PageInfoInput
  ) {
    getApplicantCallConversionList(filters: $filters, pageInfo: $pageInfo) {
      totalCount
      items {
        applicantCallConversionId
        initialCallDate
        lastCallDate
        status
        callCount
        connectedStatus
        applicant {
          applicantId
          firstName
          middleName
          lastName
          phone
        }
      }
    }
  }
`
export const GET_COMMON_DISCUSSION_TOPICS = gql`
  query GetCommonDiscussionTopics($filters: CallLogFiltersInput) {
    getCommonDiscussionTopics(filters: $filters) {
      topic
      frequency
    }
  }
`
