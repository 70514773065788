import { useContext } from 'react'

import { Divider } from '@mantine/core'

import Tag from 'src/components/DataDisplay/Tag/Tag'
import DateRangeMenu from 'src/components/Overlays/DateRangeMenu'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { formatSnakeValue } from 'src/lib/formatters'

import { callTypeOptions, updateUrl } from '../utils'

import Filters from './Filters'

const FiltersBar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  callDirections,
  setCallDirections,
  selectedRecruiters,
  setSelectedRecruiters,
  callTypes,
  setCallTypes,
  callStatuses,
  setCallStatuses,
  selectedConnectedStatus,
  setSelectedConnectedStatus,
}) => {
  const recruiters = useContext(RecruitersContext)
  return (
    <div className="flex flex-row items-center gap-4">
      <div className="flex flex-1 flex-row gap-2 overflow-auto">
        {callDirections.map((direction) => (
          <Tag
            key={direction}
            onClose={() => {
              const newDirections = callDirections.filter(
                (item) => item !== direction
              )
              updateUrl('callDirections', newDirections.join(','))
              setCallDirections(newDirections)
            }}
          >
            {direction.toLowerCase()}
          </Tag>
        ))}
        {selectedRecruiters.map((recruiter) => (
          <Tag
            key={recruiter}
            onClose={() => {
              const newRecruiters = selectedRecruiters.filter(
                (item) => item !== recruiter
              )
              updateUrl('recruiters', newRecruiters.join(','))
              setSelectedRecruiters(newRecruiters)
            }}
          >
            {recruiters.find((r) => r.value === recruiter)?.label}
          </Tag>
        ))}
        {callTypes.map((callType) => (
          <Tag
            key={callType}
            onClose={() => {
              const newCallTypes = callTypes.filter((item) => item !== callType)
              updateUrl('callTypes', newCallTypes.join(','))
              setCallTypes(newCallTypes)
            }}
          >
            {callTypeOptions.find((option) => option.value === callType).label}
          </Tag>
        ))}
        {callStatuses.map((callStatus) => (
          <Tag
            key={callStatus}
            onClose={() => {
              const newCallStatuses = callStatuses.filter(
                (item) => item !== callStatus
              )
              updateUrl('callStatuses', newCallStatuses.join(','))
              setCallStatuses(newCallStatuses)
            }}
          >
            {formatSnakeValue(callStatus)}
          </Tag>
        ))}
        {selectedConnectedStatus.map((status) => (
          <Tag
            key={status}
            onClose={() => {
              const newConnectedStatuses = selectedConnectedStatus.filter(
                (item) => item !== status
              )
              updateUrl('connectedStatus', newConnectedStatuses.join(','))
              setSelectedConnectedStatus(newConnectedStatuses)
            }}
          >
            {formatSnakeValue(status)}
          </Tag>
        ))}
      </div>

      <Divider orientation="vertical" className="ml-auto" />

      <Filters
        callDirections={callDirections}
        setCallDirections={setCallDirections}
        selectedRecruiters={selectedRecruiters}
        setSelectedRecruiters={setSelectedRecruiters}
        callTypes={callTypes}
        setCallTypes={setCallTypes}
        callStatuses={callStatuses}
        setCallStatuses={setCallStatuses}
        selectedConnectedStatus={selectedConnectedStatus}
        setSelectedConnectedStatus={setSelectedConnectedStatus}
      />

      <DateRangeMenu
        startDate={startDate}
        endDate={endDate}
        handleChange={(value) => {
          setStartDate(value[0])
          setEndDate(value[1])
          updateUrl('dateRange', value.join(','))
        }}
        clearable={false}
      />
    </div>
  )
}

export default FiltersBar
