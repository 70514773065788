import { useState } from 'react'

import { Grid } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import {
  GET_CALL_STATUSES_METRICS,
  GET_DAILY_CALL_ACTIVITY_METRICS,
} from 'src/graphql/callAnaltyics.graphql'

import AnalyticsCard from '../../AnalyticsPage/components/AnalyticsCard'
import CallAreaChart from '../components/CallAreaChart'
import CallDurationStats from '../components/CallDurationStats'
import CallSparklineCard from '../components/CallSparklineCard'
import FiltersBar from '../components/FiltersBar'
import RecruiterBarChart from '../components/RecruiterBarChart'
import TotalCallStats from '../components/TotalCallStats'
import { generateMetricsByDateRange } from '../utils'
import { getUrlParams } from '../utils'

const CallAnalyticsTab = () => {
  const urlParams = getUrlParams()
  const [startDate, setStartDate] = useState(urlParams.defaultStartDate)
  const [endDate, setEndDate] = useState(urlParams.defaultEndDate)
  const [callTypes, setCallTypes] = useState(urlParams.defaultCallTypes)
  const [selectedConnectedStatus, setSelectedConnectedStatus] = useState(
    urlParams.defaultConnectedStatus
  )
  const [callStatuses, setCallStatuses] = useState(
    urlParams.defaultCallStatuses
  )
  const [selectedRecruiters, setSelectedRecruiters] = useState(
    urlParams.defaultRecruiters
  )
  const [callDirections, setCallDirections] = useState(
    urlParams.defaultCallDirections
  )
  const [pipelineData, setPipelineData] = useState([])
  const [dailyCallActivityMetrics, setDailyCallActivityMetrics] = useState([])

  const { loading: callStatusesMetricsLoading } = useQuery(
    GET_CALL_STATUSES_METRICS,
    {
      onCompleted: (data) => {
        const callStatusesKeys = [
          'Completed',
          'No Answer',
          'Busy',
          'Failed',
          'Left Voicemail',
        ]
        setPipelineData(
          generateMetricsByDateRange(
            startDate,
            endDate,
            data?.callStatusesMetrics || [],
            callStatusesKeys,
            'callStatus'
          )
        )
      },
      variables: {
        filters: {
          connectedStatuses: selectedConnectedStatus,
          recruiters: selectedRecruiters,
          callDirections: callDirections,
          callStatuses: callStatuses,
          callTypes: callTypes,
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
        },
      },
    }
  )
  const { data, loading: dailyCallActivityMetricsLoading } = useQuery(
    GET_DAILY_CALL_ACTIVITY_METRICS,
    {
      onCompleted: (data) => {
        const callStatusesKeys = ['Inbound', 'Outbound']
        setDailyCallActivityMetrics(
          generateMetricsByDateRange(
            startDate,
            endDate,
            data?.dailyCallActivityMetrics || [],
            callStatusesKeys,
            'callDirection'
          )
        )
      },
      variables: {
        filters: {
          connectedStatuses: selectedConnectedStatus,
          recruiters: selectedRecruiters,
          callDirections: callDirections,
          callStatuses: callStatuses,
          callTypes: callTypes,
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
        },
      },
    }
  )

  return (
    <>
      <FiltersBar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        callDirections={callDirections}
        setCallDirections={setCallDirections}
        selectedRecruiters={selectedRecruiters}
        setSelectedRecruiters={setSelectedRecruiters}
        callTypes={callTypes}
        setCallTypes={setCallTypes}
        callStatuses={callStatuses}
        setCallStatuses={setCallStatuses}
        selectedConnectedStatus={selectedConnectedStatus}
        setSelectedConnectedStatus={setSelectedConnectedStatus}
      />
      <Grid gutter="xs" className="h-full overflow-y-auto overflow-x-hidden">
        <Grid.Col span={4}>
          <AnalyticsCard
            title="Summary of Call Statuses"
            loading={callStatusesMetricsLoading}
            description={'Changes in status across all calls'}
          >
            <CallSparklineCard pipelineMetrics={pipelineData} />
          </AnalyticsCard>
        </Grid.Col>

        <Grid.Col span={8}>
          <AnalyticsCard
            title="Daily Call Activity"
            loading={dailyCallActivityMetricsLoading}
            description={'Daily calls across all applicants & current drivers'}
          >
            <TotalCallStats
              dailyCallActivityMetrics={data?.dailyCallActivityMetrics || []}
            />
            <CallAreaChart type={'default'} data={dailyCallActivityMetrics} />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={8}>
          <AnalyticsCard
            title="Calls by Employee"
            description={'Number of calls by each employee'}
          >
            <RecruiterBarChart
              filters={{
                connectedStatuses: selectedConnectedStatus,
                recruiters: selectedRecruiters,
                callDirections: callDirections,
                callStatuses: callStatuses,
                callTypes: callTypes,
                dateRange: {
                  gte: startDate,
                  lte: endDate,
                },
              }}
            />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={4}>
          <AnalyticsCard
            title="Call Duration"
            loading={dailyCallActivityMetricsLoading}
            description={'Total duration'}
          >
            <CallDurationStats
              filters={{
                connectedStatuses: selectedConnectedStatus,
                recruiters: selectedRecruiters,
                callDirections: callDirections,
                callStatuses: callStatuses,
                callTypes: callTypes,
                dateRange: {
                  gte: startDate,
                  lte: endDate,
                },
              }}
            />
          </AnalyticsCard>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CallAnalyticsTab
