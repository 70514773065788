import { ResponsivePie } from '@nivo/pie'

import { ApplicantCallConversionStatus } from 'src/graphql/types/callActivity'
import { formatSnakeValue } from 'src/lib/formatters'

import { callStatusColors } from '../utils'

const assignColor = (status, connectedStatus) => {
  if (status === ApplicantCallConversionStatus.CONNECTED) {
    return callStatusColors[status][connectedStatus].color
  }
  return callStatusColors[status].color
}

const ConversionPieChart = ({ data }) => {
  const isEmpty = data?.statuses.length === 0

  //Map data to pie chart format
  const pieData = data.statuses.map((item) => {
    return {
      id:
        formatSnakeValue(item.status) +
        (item.connectedStatus
          ? `: ${formatSnakeValue(item.connectedStatus)}`
          : ''),
      label:
        formatSnakeValue(item.status) +
        (item.connectedStatus
          ? `: ${formatSnakeValue(item.connectedStatus)}`
          : ''),
      value: item.count,
      color: assignColor(item.status, item.connectedStatus),
    }
  })

  return (
    <div className="h-[225px]">
      {isEmpty ? (
        <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
          No data available
        </div>
      ) : (
        <ResponsivePie
          data={pieData}
          margin={{ top: 10, bottom: 10, right: 10, left: 10 }}
          startAngle={-120}
          innerRadius={0.5}
          padAngle={2}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          enableArcLinkLabels={true}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLabel={(e) => ((e.value / data.total) * 100).toFixed(0) + '%'}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLinkLabelsDiagonalLength={5}
          arcLinkLabelsStraightLength={5}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
        />
      )}
    </div>
  )
}

export default ConversionPieChart
