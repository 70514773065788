import { Box } from '@mantine/core'
import { ApplicantCallConversion } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import { buildFullName, formatDateCustom } from 'src/lib/formatters'
import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import { cn } from 'src/utils'

import { getStatusColor, getStatusLabel } from '../utils'

import CallConversionBadge from './CallConversionBadge'

interface ConversionTableProps {
  data: ApplicantCallConversion[]
  page: number
  loading?: boolean
  totalPages: number
  onPageChange: (page) => void
}

const ConversionTable = ({
  data,
  page,
  loading,
  totalPages,
  onPageChange,
}: ConversionTableProps) => {
  const { handleOpen } = useApplicantDrawerContext()

  const isEmpty = data?.length === 0

  if (!data) return
  if (loading) return
  return (
    <div className="h-[862px] ">
      <div className="grid grid-cols-11 gap-4 bg-doubleNickel-gray-50 p-2 text-xs">
        <div className="col-span-2">Date </div>
        <div className="col-span-3">Name </div>
        <div className="col-span-2">Phone </div>
        <div>Calls </div>
        <div className="col-span-3">Status </div>
      </div>
      <div className="flex h-[90%] flex-col overflow-auto ">
        {isEmpty ? (
          <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
            No data available
          </div>
        ) : (
          <>
            {data.map((data, index) => (
              <div
                key={index}
                className="grid grid-cols-11 items-center gap-4 px-2 py-1 text-xs hover:bg-doubleNickel-gray-50"
              >
                <div className="col-span-2">
                  {formatDateCustom(data.lastCallDate, 'MM/DD/YYYY hh:mm A')}{' '}
                </div>
                <Box
                  className={cn(
                    'col-span-3',
                    data?.applicant?.applicantId &&
                      'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline '
                  )}
                  onClick={(e) => {
                    if (data.applicant?.applicantId) {
                      //If the user holds down the meta key (command on mac, control on windows) and clicks on the link, it will open in a new tab
                      if (e.metaKey) {
                        window.open(
                          routes.applicantDetails({
                            id: data.applicant.applicantId,
                            tab: 'application',
                          }),
                          '_blank' // Opens in a new tab
                        )
                      } else {
                        handleOpen(data.applicant.applicantId)
                      }
                    }
                  }}
                >
                  {buildFullName(data.applicant)}{' '}
                </Box>
                <div className="col-span-2">
                  {formatInternationalPhoneNumber(data.applicant.phone)}{' '}
                </div>
                <div>{data.callCount} </div>
                <div className="col-span-3 flex flex-row">
                  {data?.status && (
                    <CallConversionBadge
                      status={data.status}
                      connectedStatus={data.connectedStatus}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <PaginationArea
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default ConversionTable
