import { ResponsiveFunnel } from '@nivo/funnel'

import {
  ApplicantCallConnectedStatus,
  ApplicantCallConversionStatus,
} from 'src/graphql/types/callActivity'

const ConversionFunnel = ({ data, showTransferred = false }) => {
  const isEmpty = data?.statuses.length === 0

  const total = {
    id: 'Total',
    value: 1,
    label: 'Total',
    count: data?.total,
  }

  const connected = {
    id: 'Connected',
    label: 'Connected',
    value:
      data.statuses.reduce((acc, status) => {
        if (status.status === ApplicantCallConversionStatus.CONNECTED) {
          return acc + status.percentage
        }
        return acc
      }, 0) / 100,
    count: data.statuses.reduce((acc, status) => {
      if (status.status === ApplicantCallConversionStatus.CONNECTED) {
        return acc + status.count
      }
      return acc
    }, 0),
  }

  const booked = {
    id: 'Booked',
    label: 'Booked',
    value:
      data.statuses.find(
        (status) =>
          status.status === ApplicantCallConversionStatus.CONNECTED &&
          status.connectedStatus === ApplicantCallConnectedStatus.BOOKED
      )?.percentage / 100 || 0,
    count:
      data.statuses.find(
        (status) =>
          status.status === ApplicantCallConversionStatus.CONNECTED &&
          status.connectedStatus === ApplicantCallConnectedStatus.BOOKED
      )?.count || 0,
  }

  const transferred = {
    id: 'Transferred',
    label: 'Transferred',
    value:
      data.statuses.find(
        (status) =>
          status.status === ApplicantCallConversionStatus.CONNECTED &&
          status.connectedStatus === ApplicantCallConnectedStatus.TRANSFERRED
      )?.percentage / 100 || 0,
    count:
      data.statuses.find(
        (status) =>
          status.status === ApplicantCallConversionStatus.CONNECTED &&
          status.connectedStatus === ApplicantCallConnectedStatus.TRANSFERRED
      )?.count || 0,
  }

  return (
    <div className="h-[175px] overflow-hidden">
      <div className="flex flex-row px-5">
        <div className="flex-1 text-center text-sm font-medium text-doubleNickel-gray-500">
          Total
        </div>
        <div className="flex-1 text-center text-sm font-medium text-doubleNickel-gray-500">
          Connected
        </div>{' '}
        <div className="flex-1 text-center text-sm font-medium text-doubleNickel-gray-500">
          {showTransferred ? 'Transferred' : 'Booked'}
        </div>
      </div>
      {isEmpty ? (
        <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
          No data available
        </div>
      ) : (
        <ResponsiveFunnel
          data={[total, connected, showTransferred ? transferred : booked]}
          margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
          direction="horizontal"
          shapeBlending={0.43}
          valueFormat=">-.0%"
          spacing={1}
          colors={'#1C93D7'}
          borderWidth={6}
          borderColor={{ from: 'color', modifiers: [] }}
          borderOpacity={0.4}
          labelColor={{
            from: 'color',
            modifiers: [['brighter', 10]],
          }}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          enableAfterSeparators={false}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={33}
          motionConfig="gentle"
          tooltip={({ part }) => (
            <div
              style={{
                padding: 6,
                color: 'black',
                background: '#F9FAFB',
              }}
            >
              <strong>
                {part.data.id}: {part.data.count}
              </strong>
            </div>
          )}
        />
      )}
    </div>
  )
}

export default ConversionFunnel
