import { ResponsiveBar } from '@nivo/bar'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_CALL_CONVERSION_BY_RECRUITER } from 'src/graphql/callAnaltyics.graphql'
import { cn } from 'src/utils'

const RecruiterBarChart = ({ filters }) => {
  const { data } = useQuery(GET_CALL_CONVERSION_BY_RECRUITER, {
    onError: () => {
      toast('Unable to get call duration metrics', 'error')
    },
    variables: {
      filters: filters,
    },
  })

  let recruiterConversionData = data?.getCallsByRecruiterMetric?.items || []
  const temp = [...recruiterConversionData]
  recruiterConversionData = temp.reverse()
  const isEmpty = recruiterConversionData.length === 0

  return (
    <div className={cn('h-[225px]')}>
      {isEmpty ? (
        <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
          No data available
        </div>
      ) : (
        <ResponsiveBar
          data={recruiterConversionData}
          keys={['count']}
          indexBy="recruiterName"
          margin={{ top: 25, right: 50, bottom: 25, left: 100 }}
          padding={0.3}
          enableGridY={false}
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={'#77BEE7'}
          enableTotals={true}
          enableLabel={false}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisLeft={{
            format: (label) =>
              label.length > 13 ? `${label.slice(0, 13)}...` : label,
          }}
          tooltip={(e) => (
            <div className="rounded-lg bg-white p-2 shadow-md">
              <div className="text-sm font-semibold text-doubleNickel-gray-900">
                {e.indexValue}
              </div>
              <div className="flex flex-row items-center gap-10">
                <div className="text-sm text-doubleNickel-gray-500">
                  {e.value} Call(s)
                </div>
                <div className="text-sm text-doubleNickel-gray-500">
                  {(
                    (e.value / data?.getCallsByRecruiterMetric.total) *
                    100
                  ).toFixed(0)}
                  %
                </div>
              </div>
            </div>
          )}
          axisRight={null}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 12, // Font size of axis ticks
                },
              },
            },
          }}
          axisBottom={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          barAriaLabel={(e) =>
            e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
          }
        />
      )}
    </div>
  )
}

export default RecruiterBarChart
